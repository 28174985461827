import { ReactNode } from 'react'
import cx from 'classnames'

const Footer = ({
  children,
  className,
}: {
  className?: string
  children: ReactNode
}) => {
  return (
    <section className={cx(className)}>
      <div className="shadow-line w-full" />
      <div className="p-2 text-center bg-white">{children}</div>
    </section>
  )
}

export default Footer
