import cx from 'classnames'

const StepNumber = ({
  isFilled,
  number,
}: {
  number: number
  isFilled: boolean
}) => {
  return (
    <span
      className={cx(
        'text-xs relative font-bold rounded-full w-5 h-5 flex items-center justify-center transition-all',
        {
          'bg-fa-blue text-white': isFilled,
          'text-fa-blue border-fa-blue border bg-white': !isFilled,
        }
      )}
    >
      {number}
    </span>
  )
}

const Item = ({
  number,
  title,
  isActive,
  isFilled,
}: {
  number: number
  title: string
  isActive: boolean
  isFilled: boolean
}) => {
  return (
    <li className="flex flex-col items-center space-y-1 relative">
      <div
        className={cx('absolute top-3.5 bg-fa-blue h-[1px]', {
          'left-1/2  w-1/2': number === 1,
          'left-0 w-full': number === 2,
          'right-1/2 w-1/2': number === 3,
        })}
      />
      <StepNumber number={number} isFilled={isFilled} />
      <span
        className={cx('text-1xs text-fa-blue', {
          'font-bold': isActive,
        })}
      >
        {title}
      </span>
    </li>
  )
}

const ProgressBar = ({ activeStep }: { activeStep: number }) => {
  return (
    <div>
      <ul
        className={cx('grid relative', {
          'grid-cols-3': true,
        })}
      >
        <Item
          number={1}
          title="Order"
          isFilled={activeStep >= 1}
          isActive={activeStep === 1}
        />
        <Item
          number={2}
          title="Payment"
          isFilled={activeStep >= 2}
          isActive={activeStep === 2}
        />
        <Item
          number={3}
          title="Complete"
          isFilled={activeStep >= 3}
          isActive={activeStep === 3}
        />
      </ul>
    </div>
  )
}

export default ProgressBar
