import { FC, MouseEventHandler, ReactNode } from "react";
import cx from "classnames";
import { ButtonVariant, buttonVariants } from "./config";

const AnchorButton: FC<{
  colorVariant: ButtonVariant;
  href: string;
  className?: string;
  target?: string;
  children: ReactNode;
  onClick?: MouseEventHandler;
}> = ({ children, className, colorVariant, href, ...props }) => (
  <a
    {...props}
    href={href}
    className={cx(
      "font-bold text-base rounded tracking-tight",
      buttonVariants[colorVariant],
      className
    )}
  >
    {children}
  </a>
);

export default AnchorButton;
