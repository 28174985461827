const familyAlbumStoreDeeplink =
  import.meta.env.VITE_FAMILY_ALBUM_STORE_DEEPLINK || '/'
const baseUrl = import.meta.env.VITE_PUBLIC_URL || '/'
const isProduction = import.meta.env.VITE_ENVIRONMENT === 'production'

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ENVIRONMENT,
}

const config = {
  paymentsApiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  familyAlbumStoreDeeplink,
  isProduction,
  baseUrl,
  rollbarConfig,
  familyAlbumPaymentResultDeeplink: import.meta.env
    .VITE_FAMILY_ALBUM_PAYMENT_RESULT_DEEPLINK,
}

export default config
