import { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'

const Card: FunctionComponent<{
  className?: string
  children: ReactNode
}> = ({ children, className }) => {
  return (
    <div
      className={cx(
        'bg-white p-4 border-t-object-separator-1 border-b-object-separator-1 border-solid border-y',
        className
      )}
    >
      {children}
    </div>
  )
}

export default Card
