export const buttonCommonClassName =
  "font-bold rounded tracking-tight relative";

export const buttonVariants: Record<ButtonVariant, string> = {
  primary:
    "border border-fa-primary bg-fa-primary text-white disabled:bg-button-disabled disabled:text-white disabled:border-button-disabled",
  "primary:invert":
    "border border-fa-primary bg-white text-fa-primary disabled:border-button-disabled disabled:text-button-disabled",
  "primary:invert:no-border": "border border-white bg-white text-fa-primary",
  "primary:invert:no-border:transparent": "text-fa-primary",
  secondary:
    "bg-gray-200 text-fa-primary border border-gray-200 disabled:opacity-50",
  danger: "bg-red-500 text-white border border-red-500",
  "danger:invert": "bg-white border-fa-attention text-fa-attention border",
  "black-and-white": "bg-white text-fa-text-secondary",
};

export type ButtonVariant =
  | "primary"
  | "primary:invert"
  | "primary:invert:no-border"
  | "primary:invert:no-border:transparent"
  | "secondary"
  | "danger"
  | "danger:invert"
  | "black-and-white";
