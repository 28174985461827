export const formatAmountString = (
  amount: number,
  currency: string | null | undefined
) => {
  const amountString = currency
    ? Intl.NumberFormat('en-US', {
        style: 'currency',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currency,
      }).format(amount / 100)
    : ''

  return amountString
}

export const validateGiftCardRedemptionCode = (redemptionCode: string) => {
  const regex = /([A-Z0-9]{4})-([A-Z0-9]{4})-([A-Z0-9]{4})/
  return regex.test(redemptionCode)
}
