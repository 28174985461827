const ErrorPage = ({ error, message }: { message: string; error?: any }) => {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div className="text-center space-y-2">
        <div className="text-6xl font-bold">{error || 'Unknown error'}</div>
        <p className="text-fa-text-secondary">{message}</p>
      </div>
    </div>
  )
}

export default ErrorPage
